<template>
    <div class="GoodsList">
        <div class="case-filter">
            <div class="case-filter-input">
                <!-- 输入商品名/商品货号 -->
                <van-search class="goods__search" v-model="keywords" shape="round" background="#f9f9f9" :placeholder="$t('mx_mobile.common.1665995546094')" @search="changeTab" @clear="changeTab" />
                <span class="filter-right" @click.stop="handlerFilter">
                    <i class="iconfont icon-intact-funnel"></i>
                </span>
            </div>
            <div class="case-filter-catg" v-if="category.catgId">{{category.display}}
                <i class="iconfont icon-close pull-right reset-query" @click.stop="closeMask({})"></i>
            </div>
        </div>
        <div class="GoodsList__ware" :class="{'GoodsList__ware-down': category.catgId}">
            <van-tabs v-model="activeName" @change="changeTab" class="GoodsList__ware-tabs">
                <van-tab v-for="item in goodsTabsInfo" :key="item.tabId" :name="item.tabId" :title="item.tabName">
                    <div class="scrollBox">
                        <scroll class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                            <div class="moduleCodePage">
                                <template v-for="(item) in goodsList">
                                    <item-list :key="item.spuId" :goodsData="item" :currencyShow="currencyShow" :contactList="contactList" @showActions="showActions"></item-list>
                                </template>
                            </div>
                        </scroll>
                    </div>
                    <loading v-if="isLoading" size="30px"></loading>
                    <nodata v-if="goodsList.length <= 0 && !isLoading"></nodata>
                </van-tab>
            </van-tabs>
            <van-action-sheet v-model="isShowActions" :actions="showGoodsActions" @select="onSelect" />
        </div>
        <mask-popup v-show="isShowSide" ref="maskPopup" :catgTree="catgTree" @closeMask="closeMask"></mask-popup>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemList from './ItemList/index.vue'
import Scroll from '@/components/Scroll/index'
import copyText from '@/libs/copyText.js'
import goodsLink from '@/mixin/goodsLink.js'
import MaskPopup from './MaskPopup/index.vue'
export default {
    name: 'GoodsList',
    props: {},
    components: {
        'scroll': Scroll,
        'item-list': ItemList,
        'mask-popup': MaskPopup
    },
    mixins: [goodsLink],
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Doc.1585194359043'), // '加载更多'
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Doc.1585194375114'), // '没有更多数据了'
            startY: 20,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],
            // 以上
            isLoading: false, // 控制是否显示loading图
            isLoadingMore: false, // 控制上拉加载
            keywords: '',
            activeName: 0,
            placeholder: '',
            goodsTabsInfo: [
                {
                    tabName: this.$t('mx_mobile.App.1596630336393'), // '在售商品'
                    tabId: 0
                },
                {
                    tabName: this.$t('mx_mobile.App.1596630689796'), // '推荐商品'
                    tabId: 1
                },
                {
                    tabName: this.$t('mx_mobile.App.1596630708488'), // '下架商品'
                    tabId: 2
                }
                // {
                //     tabName: '阿里商品',
                //     tabId: '3'
                // }
            ],
            goodsList: [],
            page: {
                num: 1,
                total: 0,
                from: 0,
                size: 20
            },
            isShowActions: false,
            goodsActions: [
                {
                    name: this.$t('mx_mobile.App.1596630811720'), // '复制商品详情链接'
                    optCode: 'copy_detail_link',
                    optModuleCode: 'PP001',
                    r: [0, 1, 2] // activeName  0-在售, 1-推荐, 2-下架, 3-阿里商品
                },
                {
                    name: this.$t('mx_mobile.App.1596630833760'), // '复制商品外链'
                    optCode: 'copy_custom_link',
                    optModuleCode: 'PP001',
                    r: [0, 1, 2]
                },
                {
                    name: this.$t('mx_mobile.App.1596630858468'), // '推荐'
                    optCode: 'recommend',
                    optModuleCode: 'PP001',
                    r: [0]
                },
                {
                    name: this.$t('mx_mobile.App.1596630879104'), // '下架'
                    optCode: 'offShelves',
                    optModuleCode: 'PP001',
                    r: [0, 1]
                },
                {
                    name: this.$t('mx_mobile.App.1596630897233'), // '取消推荐'
                    optCode: 'cancelRecommend',
                    optModuleCode: 'PP001',
                    r: [1]
                },
                {
                    name: this.$t('mx_mobile.App.1596630918224'), // '删除'
                    optCode: 'otDelete',
                    optModuleCode: 'PP001',
                    r: [2]
                },
                {
                    name: this.$t('mx_mobile.App.1596630951663'), // '上架'
                    optCode: 'onShelves',
                    optModuleCode: 'PP001',
                    r: [2]
                }
            ],
            showGoodsActions: [],
            actionItem: {},
            isShowSide: false,
            catgTree: [],
            category: {}
        }
    },
    computed: {
        ...mapGetters([
            'currencyShow',
            'contactList'
        ]),
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    watch: {},
    created() {
        this.getListData()
        this.getGoodsCategory()
    },
    mounted() {
    },
    methods: {
        onSelect(item) {
            this.isShowActions = false
            switch (item.optCode) {
                case 'copy_detail_link':
                    // 复制商品详情链接
                    copyText(this.getGoodsLink(this.actionItem.spuId), () => {
                        this.$toast(this.$t('mx_mobile.Doc.1585194769281')) // '复制成功'
                    })
                    break
                case 'copy_custom_link':
                    // 复制商品外链
                    copyText(this.actionItem.customLink, () => {
                        this.$toast(this.$t('mx_mobile.Doc.1585194769281')) // '复制成功'
                    })
                    break
                case 'recommend': // 推荐
                case 'offShelves': // 下架
                case 'cancelRecommend': // 取消推荐
                case 'otDelete': // 删除
                case 'onShelves': // 上架
                    item.next = this.updateGoodsList
                    this.confirmExecute(item)
                    break
                default:
                    break
            }
        },
        updateGoodsList(optCode) {
            switch (optCode) {
                case 'cancelRecommend':
                case 'offShelves':
                case 'onShelves':
                case 'otDelete':
                    this.goodsList = this.goodsList.filter(val => val.spuId != this.actionItem.spuId)
                    break
                default:
                    break
            }
        },
        confirmExecute(obj) {
            //  '是否将商品进行' 操作？
            let tip = this.$t('mx_mobile.common.1665995945919') + obj.name + this.$t('mx_mobile.common.1665996001132')
            this.$dialog.confirm({
                message: tip
            }).then(() => {
                this.operateItem(obj)
            }).catch(() => {
            })
        },
        async operateItem(obj) {
            try {
                let params = {
                    optModuleCode: 'PP001',
                    targets: this.actionItem.spuId,
                    optCode: obj.optCode
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.document_operate_listOpt_put
                let res = await this.axios.put(url, { ...params })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.failNum == 0) {
                        this.$toast(res.msg)
                        if (typeof obj.next == 'function') {
                            obj.next(obj.optCode)
                        }
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        showActions(item) {
            this.actionItem = JSON.parse(JSON.stringify(item))
            this.showGoodsActions = this.goodsActions.filter(val => val.r.includes(this.activeName))
            if (!item.customLink) {
                this.showGoodsActions = this.showGoodsActions.filter(v => v.optCode != 'copy_custom_link') // 过滤商品外链
            }
            this.isShowActions = true
        },
        handlerFilter() {
            this.isShowSide = true
            this.$nextTick(() => {
                this.$refs.maskPopup.initActiveId(this.category)
            })
        },
        changeTab(name, title) {
            this.page.num = 1
            this.goodsList = []
            this.isLoadingMore = false
            this.getListData()
            this.getGoodsCategory()
        },
        async onPullingDown() {
            this.page.num = 1
            this.goodsList = []
            await this.getListData()
            this.$nextTick(() => {
                let scrollList = this.$refs.scroll
                scrollList[this.activeName].forceUpdate()
            })
        },
        async onPullingUp() {
            // 上拉加载
            if (!this.isLoadingMore) {
                await this.getListData()
                this.$nextTick(() => {
                    let scrollList = this.$refs.scroll
                    scrollList[this.activeName].forceUpdate(true) // 加载更多
                })
            } else {
                if (this.goodsList.length !== 0) {
                }
                this.$nextTick(() => {
                    let scrollList = this.$refs.scroll
                    scrollList[this.activeName].forceUpdate(false) // 没有更多数据
                })
            }
        },
        async getListData() {
            try {
                if (this.page.num === 1) this.isLoading = true
                this.isLoadingMore = true
                // activeName  0-在售, 1-推荐, 2-下架, 3-阿里商品
                this.page.from = (this.page.num - 1) * this.page.size
                let data = {
                    moduleCode: 'PP001',
                    searchType: 'list',
                    from: this.page.from,
                    size: this.page.size,
                    order: 'desc', // 降序
                    sort: 'createDate'
                }
                if (this.activeName == 0) {
                    data['source'] = 0 // 0-孚盟商品, 1-阿里商品
                    data['offFlag'] = '0' // 0-上架, 1-上架
                } else if (this.activeName == 1) {
                    data['recommendFlag'] = 1 // // 推荐
                    data['offFlag'] = '0'
                } else if (this.activeName == 2) {
                    data['offFlag'] = '1'
                } else if (this.activeName == 3) {
                    data['offFlag'] = '0'
                    data['source'] = 1
                    data['shopId'] = ''
                }
                if (this.keywords != '') {
                    data['keywords'] = this.keywords
                }
                if (this.category.catgId) {
                    data['category'] = this.category.catgId
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.document_product
                let res = await this.axios.get(url, { params: data })
                this.isLoading = false
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    this.page.total = res.data.totalNum
                    if (res.data.list && res.data.list.length >= 0) {
                        this.goodsList = [...this.goodsList, ...res.data.list]
                        if (this.page.size === res.data.list.length) {
                            this.page.num++
                            this.isLoadingMore = false
                        } else {
                            this.isLoadingMore = true
                        }
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                this.isLoading = false
                console.log(err)
            }
        },
        async getGoodsCategory() {
            // activeName  0-在售, 1-推荐, 2-下架, 3-阿里商品
            // catgFlag: 0-孚盟商品大类, 1-阿里商品大类, 不传默认混合类目
            // 先取左大类菜单
            try {
                let params = {
                    type: 'inUse'
                }
                if (this.activeName == 0) {
                    params['catgFlag'] = 0
                } else if (this.activeName == 3) {
                    params['catgFlag'] = 1
                } else {
                    params['catgFlag'] = 0 // 默认加载孚盟商品大类导航
                }
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.document_product_category
                let res = await this.axios.get(url, { params })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    this.catgTree = res.data.list || []
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        closeMask(category) {
            this.category = { ...category }
            this.isShowSide = false
            this.page.num = 1
            this.goodsList = []
            this.isLoadingMore = false
            this.getListData()
        }
    },
    updated() { },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
