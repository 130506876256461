<template>
    <li class="goods__tree">
        <div @click="menuClick(treeData)" class="goods__tree-trunk" :class="{'goods__tree-bg': activeId == treeData.catgId, 'goods__tree-1': styleType == '1','goods__tree-2': styleType == '2' }">
            <span class="open" :class="[styleType == '1' ? 'open1' : 'open2']" v-if="isFolder"><i class="iconfont" :class="[open ? 'icon-minus-thin': 'icon-plus-file']"></i></span>
            <span>{{treeData.display}}</span>
        </div>
        <ul v-if="isFolder&&open">
            <goods-tree v-for="item in treeData.categoryList" :key="item.catgId" :treeData="item" :activeId="activeId" styleType="2" @click="menuClick(item, 2)"></goods-tree>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'GoodsTree',
    props: {
        treeData: {
            type: Object,
            default: () => ({})
        },
        activeId: {
            type: String,
            default: ''
        },
        styleType: {
            type: String,
            default: '1'
        }
    },
    components: {},
    data() {
        return {
            open: false
        }
    },
    computed: {
        isFolder() {
            return this.treeData.categoryList && this.treeData.categoryList.length > 0
        }
    },
    watch: {
    },
    created() { },
    mounted() { },
    methods: {
        menuClick(item, type) {
            if (this.isFolder && type != 2) {
                this.open = !this.open
            }
            this.$emit('click', item)
        }
    },
    updated() { },
    beforeDestroy() { }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
