<template>
    <div class="goodsItem" @click="viewDetail">
        <div class="goodsItem__img">
            <img class="text-hover goodsItem__img-show" :src="avatarSrc(goodsData, '90x90')" alt="img">
        </div>
        <div class="goodsItem__intro">
            <div class="goodsItem__intro-code" v-if="goodsData.spuCode">{{goodsData.spuCode}}</div>
            <div class="goodsItem__intro-name" v-if="goodsData.spuName">{{goodsData.spuName}}</div>
            <!-- 拥有人 -->
            <div class="goodsItem__intro-owner" v-if="goodsData.ownerCtId">{{`${$t('mx_mobile.Client.1627009054565')}：` + peopleListReturn(goodsData.ownerCtId)}}</div>
            <div class="goodsItem__intro-price">{{valueText + (goodsData.salePrice || 0)}}</div>
        </div>
        <div class="goodsItem__ctrl">
            <i class="icon-more iconfont" @click.stop="showActions"></i>
        </div>
    </div>
</template>

<script>
import goodsLink from '@/mixin/goodsLink.js'
export default {
    name: 'ItemList',
    props: {
        goodsData: {
            type: Object,
            default: () => ({})
        },
        currencyShow: {
            type: Object,
            default: () => ({})
        },
        contactList: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
    },
    mixins: [goodsLink],
    data() {
        return {
            valueText: ''
        }
    },
    computed: {
    },
    watch: {},
    created() {
    },
    mounted() {
        this.getCurrencyData()
    },
    methods: {
        viewDetail() {
            window.open(this.getGoodsLink(this.goodsData.spuId))
        },
        // 图像
        avatarSrc(list, value) {
            let imgId
            if (!list.imagesId || list.imagesId.length === 0) {
                return '/images/goods/noImg.jpg'
            } else {
                imgId = list.imagesId[0] // 取第一个数组项为默认头像
                return this.getGlobalImgSrc(imgId, value)
            }
        },
        openGoodsDetail() {
        },
        getCurrencyData() {
            if (this.goodsData.saleCur && this.goodsData.saleCur != '') {
                this.valueText = this.currencyShow[this.goodsData.saleCur] ? this.currencyShow[this.goodsData.saleCur].symbol : ''
            }
        },
        peopleListReturn(ctId) {
            if (!ctId || ctId == '') {
                return ''
            }
            if (ctId == '10000') {
                return this.$t('mx_mobile.common.1665996322076') // '系统'
            }
            let content = ''
            if (this.contactList instanceof Object) {
                content = this.contactList[ctId] || ''
            }
            return content
        },
        showActions() {
            this.$emit('showActions', this.goodsData)
        }
    },
    updated() { },
    beforeDestroy() { }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
