<template>
    <div class="mask__popup">
        <transition name="fade">
            <div class="sliderBg" @click="$emit('closeMask', catgInfo)"></div>
        </transition>
        <transition name="slider-customer">
            <div class="sliderBody">
                <!-- 商品类目 -->
                <div class="filter-key">{{$t('mx_mobile.common.1665996491901')}}</div>
                <div class="goods__category">
                    <ul class="goods__category-tree" v-for="item in catgTree" :key="item.catgId">
                        <goods-tree :treeData="item" :activeId="catgInfo.catgId + ''" @click="treeItemClick"></goods-tree>
                    </ul>
                </div>
                <div class="handler-btn">
                    <!-- 重置 -->
                    <van-button @click.stop="exeFilter('reset')" class="cus-btn" type="default">{{$t('mx_mobile.Client.1585286717723')}}</van-button>
                    <!-- 完成 -->
                    <van-button @click.stop="exeFilter('done')" class="cus-btn" type="danger">{{$t('mx_mobile.Client.1585286721404')}}</van-button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import GoodsTree from '../GoodsTree/index.vue'
export default {
    name: 'MaskPopup',
    props: {
        catgTree: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        'goods-tree': GoodsTree
    },
    data() {
        return {
            catgInfo: {}
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        initActiveId(obj) {
            this.catgInfo = { ...obj }
        },
        exeFilter(type) {
            if (type == 'reset') {
                this.catgInfo = {}
            }
            this.$emit('closeMask', this.catgInfo)
        },
        treeItemClick(item) {
            this.catgInfo = { ...item }
        }
    },
    updated() { },
    beforeDestroy() { }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
