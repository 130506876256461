import Clipboard from 'clipboard'
export default function copyText(text = '', callback) {
    let _resolve
    let tempBtn = document.createElement('div')
    let clipboard = new Clipboard(tempBtn, {
        text: () => { return text }
    })
    clipboard.on('success', (e) => {
        clipboard.destroy()
        tempBtn = null
        if (callback) {
            callback()
        } else if (_resolve) {
            _resolve()
        }
    })

    if (!callback) {
        return new Promise(resolve => {
            _resolve = resolve
            tempBtn.click()
        })
    } else {
        tempBtn.click()
    }
}
